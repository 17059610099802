import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Carousel from "react-bootstrap/Carousel"

import "../styles/styles.scss"
import msPartnerAnimated from "../images/ms-partner-animated.gif"

import SEO from "../components/seo"
import { Button, Container, Nav, Navbar } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import "typeface-raleway"

const IndexPage = ({ data }) => (
  <Container id="page">
    <SEO
      title="Syswright - quality data solutions for 15 years"
      description="For more than 15 years, Syswright has been working with clients to solve data and technology challenges, from consultancy and troubleshooting to systems integrations, migrations and enterprise-level data strategy. We care about building the best solutions, and we care about building for the future.​"
      keywords="syswright, data solutions, london, quality, sql server, charity"
    />

    <Navbar>
      <Navbar.Brand>
        <Img
          fixed={data.logo.childImageSharp.fixed}
          alt="Syswright Logo"
          withWebP
        />
      </Navbar.Brand>
      <Nav>
        <Nav.Link href="#contact">
          <Button variant="primary">Contact us today</Button>
        </Nav.Link>
      </Nav>
    </Navbar>

    <BackgroundImage
      id="hero"
      tag="section"
      fluid={data.hero.childImageSharp.fluid}
    >
      <h1>
        Build the best solutions.
        <br />
        Build for the future.
      </h1>
      <p>We help businesses get the most out of their data assets.</p>
    </BackgroundImage>

    <BackgroundImage
      id="offering"
      tag="section"
      fluid={data.blueGeometric.childImageSharp.fluid}
    >
      <Img
        fluid={data.dbIcon.childImageSharp.fluid}
        withWebP
        alt="Integration of data systems."
        className="offering-icon"
        imgStyle={{ objectFit: "contain" }}
      />
      <div id="offering-content">
        <h1>Data solutions that deliver.</h1>
        <p>
          You need to know that you're getting the maximum value possible out of
          your data systems. We build solutions completely tailored to your
          goals that ensure ROI.
        </p>
        <p className="list-header">Our specialist services include:</p>
        <ul>
          <li>Microsoft data platforms (SQL Server)</li>
          <li>Consultancy &amp; troubleshooting</li>
          <li>System integrations</li>
          <li>Migrations &amp; upgrades</li>
          <li>Collaboration &amp; support</li>
        </ul>
      </div>
    </BackgroundImage>

    {/*<section id="proof">*/}
    {/*  <div id="proof-top">*/}
    {/*    */}{/* <img*/}{/*
    */}{/*      src={msPartnerAnimated}*/}{/*
    */}{/*      alt="Gold Microsoft Partner for Data Analytics &amp; Data Platform"*/}{/*
    */}{/*    /> */}
    {/*    <Img*/}
    {/*      fluid={data.msPartnerLarge.childImageSharp.fluid}*/}
    {/*      alt="Microsoft Partner"*/}
    {/*      withWebP*/}
    {/*      imgStyle={{ objectFit: "contain" }}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  */}{/* <div id="proof-bottom">*/}{/*
    */}{/*    <figure>*/}{/*
    */}{/*      <q>We've got time back to focus on the important things.</q>*/}{/*
    */}{/*      <cite>John Smith, Company &amp; Company</cite>*/}{/*
    */}{/*    </figure>*/}{/*
    */}{/*    <p>*/}{/*
    */}{/*      <i className="bi bi-chevron-double-down"></i>*/}{/*
    */}{/*      More testimonials*/}{/*
    */}{/*      <i className="bi bi-chevron-double-down"></i>*/}{/*
    */}{/*    </p>*/}{/*
    */}{/*  </div> */}
    {/*</section>*/}

    {/* <section id="testimonials">
      <Carousel indicators={false}>
        <Carousel.Item>
          <figure>
            <q>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum viverra ac orci sit amet mattis. Maecenas rutrum
              ultricies ultrices. Duis eu quam ipsum. Mauris eu tincidunt felis.
              Aliquam ac libero sollicitudin, facilisis augue et, tempus quam.
            </q>
            <cite>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </cite>
          </figure>
          <Img
            fluid={data.rnidLogo.childImageSharp.fluid}
            withWebP
            className="testimonialImage"
            imgStyle={{ objectFit: "contain" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <figure>
            <q>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum viverra ac orci sit amet mattis. Maecenas rutrum
              ultricies ultrices. Duis eu quam ipsum. Mauris eu tincidunt felis.
              Aliquam ac libero sollicitudin, facilisis augue et, tempus quam.
            </q>
            <cite>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </cite>
          </figure>
          <Img
            fluid={data.saveTheChildrenLogo.childImageSharp.fluid}
            withWebP
            className="testimonialImage"
            imgStyle={{ objectFit: "contain" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <figure>
            <q>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum viverra ac orci sit amet mattis. Maecenas rutrum
              ultricies ultrices. Duis eu quam ipsum. Mauris eu tincidunt felis.
              Aliquam ac libero sollicitudin, facilisis augue et, tempus quam.
            </q>
            <cite>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </cite>
          </figure>
          <Img
            fluid={data.catsProtectionLogo.childImageSharp.fluid}
            withWebP
            className="testimonialImage"
            imgStyle={{ objectFit: "contain" }}
          />
        </Carousel.Item>
      </Carousel>
    </section> */}

    <section id="outcome">
      <div id="outcome-left">
        <h2>
          The
          <br /> Syswright
          <br /> Promise
        </h2>
        <p>
          Our services offer more than just a quick fix to an immediate problem
          – we build for the future, always.​
        </p>

        <Img
          fluid={data.telescopeIcon.childImageSharp.fluid}
          withWebP
          alt="A telescope looking towards the distance."
          className="outcome-icon"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
      <div id="outcome-right">
        <p id="leader">
          With better data management, companies can recover and redeploy up to
          35% of their spend.​
        </p>
        <p>
          Source:{" "}
          <a
            className="sourceLink"
            href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/reducing-data-costs-without-jeopardizing-growth"
            title="Reducing data costs without jeopardizing growth"
          >
            McKinsey
          </a>
        </p>
      </div>
    </section>

    <section id="call-to-action">
      <h2>
        Leverage your digital power. ​ Focus on what's important with data
        solutions that maximise your opportunities and return on investment.​
      </h2>

      <div id="calls">
        <div className="call">
          <p>Optimise insights and performance​</p>
          <div className="call-icon">
            <Img
              fluid={data.productivityIcon.childImageSharp.fluid}
              withWebP
              alt="A dial measuring improved performance."
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        </div>

        <div className="call">
          <p>Improve analytics and intelligence​</p>
          <div className="call-icon">
            <Img
              fluid={data.reportingIcon.childImageSharp.fluid}
              alt="A report enabling business intelligence."
              withWebP
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        </div>

        <div className="call">
          <p>Connect, integrate and grow​</p>
          <div className="call-icon">
            <Img
              fluid={data.cloudIcon.childImageSharp.fluid}
              alt="Connecting systems into an integrated whole."
              withWebP
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
    </section>

    <section id="contact">
      <div id="contact-left">
        <h2>About Us</h2>
        <p>
          For more than 15 years, Syswright has been working with clients to
          solve data and technology challenges, from consultancy and
          troubleshooting to systems integrations, migrations and
          enterprise-level data strategy. We care about building the best
          solutions, and we care about building for the future.​
        </p>
        <Img
          fixed={data.logoBlackAndWhite.childImageSharp.fixed}
          alt="Syswright logo in monochrome"
          withWebP
        />
      </div>
      <div id="contact-right">
        <h2>Contact</h2>
        <div id="linked-in">
          <Img
            fixed={data.linkedInIcon.childImageSharp.fixed}
            alt="Linked In Logo"
            className="linkedin-icon"
            withWebP
          />
          Find us on LinkedIn
        </div>
        <a href="mailto:info@syswright.co.uk?subject=Website Enquiry">
          info@syswright.co.uk
        </a>
        <address>
          Syswright Limited, <br />
          4 Station Cottages, <br />
          Craig Close, <br />
          Crowhurst, <br />
          Battle, <br />
          TN33 9DE, <br />
          United Kingdom
        </address>
        <p>+44 1892 342910</p>
        <p>
          <a
            id="terms-and-conditions"
            href={"/syswright-website-terms-and-conditions-v1.1.pdf"}
          >
            Terms and Conditions
          </a>
        </p>
        <p>Copyright © {new Date().getFullYear()} - All Rights Reserved</p>
      </div>
    </section>
  </Container>
)

export default IndexPage

// export const currentYear = new Date().getFullYear()
export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo-with-text.png" }) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    logoBlackAndWhite: file(
      relativePath: { eq: "logo-with-text-black-and-white.png" }
    ) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    linkedInIcon: file(relativePath: { eq: "linked-in-icon.png" }) {
      childImageSharp {
        fixed(height: 50, width: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    hero: file(relativePath: { eq: "brooke-cagle.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    blueGeometric: file(relativePath: { eq: "blue-geometric-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    dbIcon: file(relativePath: { eq: "db-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    rnidLogo: file(relativePath: { eq: "rnid-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    saveTheChildrenLogo: file(
      relativePath: { eq: "save-the-children-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    catsProtectionLogo: file(relativePath: { eq: "cats-protection-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    telescopeIcon: file(relativePath: { eq: "telescope-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    cloudIcon: file(relativePath: { eq: "cloud-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    productivityIcon: file(relativePath: { eq: "productivity-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    reportingIcon: file(relativePath: { eq: "reporting-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    msPartner: file(relativePath: { eq: "ms-partner.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    msPartnerLarge: file(relativePath: { eq: "ms-partner-large.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
